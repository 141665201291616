import { defineStore } from 'pinia'
import { getAuthCookie, setAuthCookie, removeAuthCookie } from '~/utils/auth-cookie'
import AuthApi from '~/api/AuthApi'
import type { EmployerUser } from '~/ts/interfaces/models/employerUser.interface'
import type { AuthToken } from '~/ts/interfaces/services/auth.service'

interface AuthState {
  authUser: EmployerUser | null
  bearerToken: string | null
  isLoggedIn: boolean
  authorizationLevel: string | null
  personInfo: {
    name: string | null
    email: string | null
    dob: string | null
    gender: string | null
    number: string | null
    contractorName: string | null
    employerName: string | null
    imgSrc: string | null
  }
  termsVersion: number
  policyVersion: number
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    authUser: null,
    bearerToken: getAuthCookie(),
    isLoggedIn: !!getAuthCookie(),
    authorizationLevel: null,
    personInfo: {} as AuthState['personInfo'],
    termsVersion: 0,
    policyVersion: 0
  }),

  actions: {
    async fetchAuthUser() {
      await AuthApi.authUser()
        .then((response) => {
          this.authUser = response
        })
        .catch(() => this.resetAuthUser())
    },

    async fetchTermsAndPolicyVersion(): Promise<void> {
      await AuthApi.termsAndPolicyVersion().then((response) => {
        this.termsVersion = response.terms_version
        this.policyVersion = response.policy_version
      })
    },

    setAuthUser(data: AuthToken) {
      this.authUser = data.user

      setAuthCookie(data.token)
      this.bearerToken = data.token
      this.isLoggedIn = !!data.token
      this.authUser = data.user
      this.authorizationLevel = data.user?.authorization_level?.authorization_level || null
    },

    resetAuthUser() {
      this.authUser = null
      this.bearerToken = null
      this.isLoggedIn = false
      this.authorizationLevel = null
      removeAuthCookie()

      // @ts-ignore
      this.router.push({ name: 'auth-login' })
    }
  }
})
