import { $http } from '~/plugins/interceptors'
import type { EmployerUser } from '~/ts/interfaces/models/employerUser.interface'
import type { AuthToken, LoginResponse } from '~/ts/interfaces/services/auth.service'

export default {
  async login(payload: object): Promise<LoginResponse> {
    const { data } = await $http.post<LoginResponse>(`/login`, payload)
    return data
  },

  async twoFA(payload: object): Promise<AuthToken> {
    const { data } = await $http.post<AuthToken>(`/google2fa`, payload)
    return data
  },

  async forgotPassword(payload: object): Promise<{ message: string }> {
    const { data } = await $http.post<{ message: string }>(`/forgot-password`, payload)
    return data
  },

  async resetPassword(payload: object): Promise<{ message: string }> {
    const { data } = await $http.post<{ message: string }>(`/reset-password`, payload)
    return data
  },

  async changePassword(payload: object): Promise<{ message: string }> {
    const { data } = await $http.post<{ message: string }>(`/change-password`, payload)
    return data
  },

  async authUser(params = undefined): Promise<EmployerUser> {
    const { data } = await $http.get<EmployerUser>(`/auth-user`, params)
    return data
  },

  async logout(): Promise<void> {
    const { data } = await $http.post<void>(`/logout`)
    return data
  },

  async termsAndPolicyVersion() {
    const { data } = await $http.get<{ terms_version: number; policy_version: number }>(
      `/settings/terms-and-policy-version`
    )
    return data
  }
}
