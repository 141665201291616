export enum ButtonTypes {
  Button = 'button',
  Submit = 'submit',
  Reset = 'reset'
}

export enum AuthorizationLevels {
  System = 'system',
  Client = 'client',
  Employer = 'employer',
  Division = 'division',
  Department = 'department',
  Person = 'person'
}

export enum SEARCH_TYPE {
  INTELLIGENT = 'intelligent',
  ADVANCED = 'advanced'
}

export enum OptionType {
  WAZO = 'wazo',
  VANGNET = 'vangnet',
  OTHER = 'other'
}

export enum Degree {
  AlMOST_CERTAIN = 1,
  DOUBT = 2
}

export enum GENDER {
  MALE = 'M',
  FEMALE = 'F',
  UNKNOWN = 'U'
}

export enum ExcelImportResponseType {
  Error = 'error',
  Warning = 'warning'
}

export enum ActionListTypes {
  Contractor = 'contractor',
  Employer = 'employer',
  Division = 'division',
  Department = 'department',
  Person = 'person',
  Dashboard = 'dashboard'
}

export enum ActionButtonType {
  LINK = 'link',
  MODAL = 'modal'
}

export enum TASK_STATUS {
  PENDING = 'pending',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  HALT = 'halt',
  NO_SHOW = 'no_show'
}

export enum ACTION_LIST_STATUS {
  PENDING = 'pending',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  NO_SHOW = 'no_show',
  DELETED = 'deleted',
  EXECUTED = 'executed',
  INVOICED = 'invoiced',
  PAID = 'paid'
}

export enum TemplateType {
  SURVEY = 'survey',
  FORM = 'form',
  EMAIL = 'email',
  SMS = 'sms'
}

export enum USER_MODELS {
  EMPLOYER = 'App\\Models\\Users\\EmployerUser',
  PROFESSIONAL = 'App\\Models\\Users\\Professional',
  PERSON = 'App\\Models\\Users\\FileUser',
  ADMIN = 'App\\Models\\Users\\AdminUser'
}

export enum NOTIFICATION_TYPE {
  NOTIFICATION = 'notification',
  ALERT = 'alert'
}

export enum EntityType {
  Client = 'client',
  Employer = 'employer',
  Division = 'division',
  Department = 'department'
}

export enum OnlineStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
  AWAY = 'away'
}

export enum UserType {
  ADMIN = 'admin',
  PROFESSIONAL = 'professional',
  EMPLOYER = 'employer',
  PERSON = 'person'
}

export enum LanguageEnum {
  NL = 'nl',
  EN = 'en'
}

export enum ChartTypeEnum {
  FREQUENCY = 'Frequency',
  PERCENTAGE = 'Percentage'
}
export enum TimeFrame {
  YEAR = 'Year',
  MONTH = 'Month',
  THREE_MONTHS = '3-Months'
}

export enum TaskAssignChoice {
  YOURSELF = 'assign-yourself',
  PERSON = 'person',
  EMPLOYER_USER = 'employer-user'
}
