import { createRouter, createWebHistory } from 'vue-router'
import { routes } from 'vue-router/auto-routes'

/**
 * Register router
 */
export default createRouter({
  history: createWebHistory(),
  routes
})
