import PermissionsManager from '~/permissions'

export const hasPermission = (expression: string): boolean => {
  if (!expression) {
    return true
  }

  try {
    return checkPermissionWithHierarchy(expression)
  } catch (e) {
    console.error('Error evaluating permission expression:', e)
    return false
  }
}

function checkPermissionWithHierarchy(permission: string): boolean {
  const parts = permission.split('.')
  let basePermission = parts[0]

  for (let i = 1; i < parts.length; i++) {
    basePermission += '.' + parts[i]
    if (!PermissionsManager.hasPermission(basePermission)) {
      return false
    }
  }

  return true
}
