class PermissionsManager {
  private permissions: string[] = []

  setPermissions(permissions: string[]) {
    this.permissions = permissions
  }

  hasPermission(permission: string): boolean {
    if (this.permissions.includes(permission)) {
      return true
    }

    const parts = permission.split('.')

    for (let i = parts.length; i > 0; i--) {
      const checkPermission = parts.slice(0, i).join('.')
      const wildcardPermission = `${checkPermission}.*`

      if (
        this.permissions.includes(checkPermission) ||
        this.permissions.includes(wildcardPermission)
      ) {
        return true
      }
    }

    const permissionPrefix = `${permission}.`
    if (this.permissions.some((perm) => perm.startsWith(permissionPrefix))) {
      return true
    }

    if (this.permissions.includes('*')) {
      return true
    }

    return false
  }
}

export default new PermissionsManager()
